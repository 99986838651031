import { defineStore } from "pinia";

export const useCounterStore = defineStore("counter", {
	state: () => ({
		count: 0,
	}),
	actions: {
		incrementAndDecrement(isIncrement) {
			if (isIncrement) {
				this.count++;
			} else this.count--;
		},
	},
});
